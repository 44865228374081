import React from 'react';

import './extra.css';

const Confirm = ({title, text, onConfirm, closeModal}) => {

    async function handleConfirm(){
        onConfirm();
    }

    function handleClickOutside(){
        closeModal(false);
    }

    return (
        <div className="confirm_outer_container" onClick={handleClickOutside}>
            <div className='confirm_container'>
                <p className='confirm_title'>{title}</p>
                <p className='confirm_body'>{text}</p>
                <div className='confirm_bottom'>
                    <button onClick={() => closeModal(false)} className='confirm_button'>Cancel</button>
                    <button onClick={handleConfirm} className='confirm_button'>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default Confirm;