class GamePiece {
    constructor(name, url, id, isAttacked, color, movement, x, y){
        this.name = name;
        this.url = url;
        this.id = id;
        this.isAttacked = isAttacked;
        this.color = color;
        this.movement = movement;
        this.x = x;
        this.y = y;
    }

    getPossibleMoves(neg){
        let possible = [];
        this.movement.forEach((tuple) => {
            const X = tuple[0];
            const Y = tuple[1];
            const newPos = [this.x+(neg*X), this.y-(neg*Y)];
            possible.push(newPos);
        });
        return possible;
    }

    setPosition(x, y){
        this.x = x;
        this.y = y;
    }

}

export function getPiece(data, id, color){
    let piece;
    switch(Number(id)){
        case 1:
            piece = new Leviathan(color, data.x, data.y);
            break;
        case 2:
            piece = new Hydra(color, data.x, data.y);
            break;
        case 3:
            piece = new Kraken(color, data.x, data.y);
            break;
        case 4:
            piece = new WhiteWhale(color, data.x, data.y);
            break;
        case 5:
            piece = new Megalodon(color, data.x, data.y);
            break;
        case 6:
            piece = new Siren(color, data.x, data.y);
            break;
        case 7:
            piece = new GiantSquid(color, data.x, data.y);
            break;
        case 8:
            piece = new Ebirah(color, data.x, data.y);
            break;
        case 9:
            piece = new Jormungandr(color, data.x, data.y);
            break;
        case 10:
            piece = new LochNess(color, data.x, data.y);
            break;
        default:
            
    }
    return piece;
}

export function getMonsterNames(id){
    let name = "";
    let url = "";
    switch(Number(id)){
        case 1:
            name = "Leviathan";
            url = "leviathan";
            break;
        case 2:
            name = "Sea Hydra";
            url = "sea_hydra";
            break;
        case 3:
            name = "Kraken";
            url = "kraken";
            break;
        case 4:
            name = "White Whale";
            url = "white_whale";
            break;
        case 5:
            name = "Megalodon";
            url = "megalodon";
            break;
        case 6:
            name = "Siren";
            url = "siren";
            break;
        case 7:
            name = "Giant Squid";
            url = "giant_squid";
            break;
        case 8:
            name = "Ebirah";
            url = "ebirah";
            break;
        case 9:
            name = "Jormungandr";
            url = "jormungandr";
            break;
        case 10:
            name = "Loch Ness";
            url = "loch_ness";
            break;
        default:  
            console.log("Could not find monster with id:", id);
    }

    const monster = {
        id: id,
        name: name,
        url: url
    }

    return monster;
}

export const monsterList = [{id:1, name: "Leviathan", url: "leviathan"}, {id:2, name: "Sea Hydra", url: "sea_hydra"}, {id:3, name: "Kraken", url: "kraken"}, {id:4, name: "White Whale", url: "white_whale"},
{id:5, name: "Megalodon", url: "megalodon"},{id:6, name: "Siren", url: "siren"}, {id:7, name: "Giant Squid", url: "giant_squid"}, {id:8, name: "Ebirah", url: "ebirah"}, {id:9, name: "Jormungandr", url: "jormungandr"}, {id:10, name: "Loch Ness", url: "loch_ness"}];

export class Leviathan extends GamePiece {
    constructor(color, x, y){
        const movement = [[0,-3], [-1,-2], [0,-2], [1,-2], [0, -1], [-1, 0], [1, 0], [0, 1], [-1, 2], [0, 2], [1, 2], [0, 3]];
        super("Levithan", "leviathan", 1, false, color, movement, x, y);
    }
}

export class Hydra extends GamePiece {
    constructor(color, x, y){
        const movement = [[-2, -2], [2, -2], [-1, -1], [1, -1], [-1, 1], [1, 1], [-2, 2], [2, 2]];
        super("Sea Hydra", "sea_hydra", 2, false, color, movement, x, y);
    }
}

export class Kraken extends GamePiece {
    constructor(color, x, y){
        const movement = [[-1, -2], [1, -2], [-2, -1], [-1, -1], [1, -1], [2, -1], [-2, 1], [-1, 1], [1, 1], [2, 1], [-1, 2], [1, 2]];
        super("Kraken", "kraken", 3, false, color, movement, x, y);
    }
}

export class WhiteWhale extends GamePiece {
    constructor(color, x, y){
        const movement = [[0, -1], [-1, 0], [1, 0], [-1, 1], [0, 1], [1, 1], [-1, 2], [0, 2], [1, 2], [-1, 3], [0, 3], [1, 3]];
        super("White Whale", "white_whale", 4, false, color, movement, x, y);
    }
}

export class Megalodon extends GamePiece {
    constructor(color, x, y){
        const movement = [[-1, -1], [0, -1], [1, -1], [-2, 1], [-1, 1], [0, 1], [1, 1], [2, 1], [-2, 2], [-1, 2], [0, 2], [1, 2], [2, 2]];
        super("Megalodon", "megalodon", 5, false, color, movement, x, y);
    }
}

export class Siren extends GamePiece {
    constructor(color, x, y){
        const movement = [[0, -1], [-1, 0], [1, 0], [0, 1], [0, 2], [0, 3], [0, 4]];
        super("Siren", "siren", 6, false, color, movement, x, y);
    }
}

export class GiantSquid extends GamePiece {
    constructor(color, x, y){
        const movement = [[0, -3], [0, -2], [0, -1], [-3, 0], [-2, 0], [-1, 0], [1, 0], [2, 0], [3, 0], [0, 1], [0, 2], [0, 3]];
        super("Giant Squid", "giant_squid", 7, false, color, movement, x, y);
    }
}

export class Ebirah extends GamePiece {
    constructor(color, x, y){
        const movement = [[-1, -3], [0, -3], [1, -3], [-1, -2], [0, -2], [1, -2], [0, -1], [-1, 1], [-1, 2], [1, 1], [1, 2]];
        super("Ebirah", "ebirah", 8, false, color, movement, x, y);
    }
}

export class Jormungandr extends GamePiece {
    constructor(color, x, y){
        const movement = [[-3, 1], [-2, 2], [-1, 3], [0, 2], [1, 1], [-1, -1], [0, -2], [1, -3], [2, -2], [3, -1]];
        super("Jormungandr", "jormungandr", 9, false, color, movement, x, y);
    }
}

export class LochNess extends GamePiece {
    constructor(color, x, y){
        const movement = [[0, -2], [-4, 0], [-2, 0], [2, 0], [4, 0], [0, 2], [0, 4]];
        super("Loch Ness", "loch_ness", 10, false, color, movement, x, y);
    }
}