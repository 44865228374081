import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";

import { IMAGES } from '../game/model/images';

import UserService from '../services/user.service';

import './login.css';

const CreateAccount = () => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRePassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('authenticated', false);
        secureLocalStorage.removeItem('user');
    }, []);

    async function create(event) {
        event.preventDefault();
        if(username === "" || email === "" || password === "") return;

        const data = {
            user: {
                email: email,
                username: username,
                password: password,
            }
        }

        try {
            const response = await UserService.create(data);
            // console.log(response.data);
            const user = response.data;
            user.guest = false;
            // console.log("Created: ", user);
            secureLocalStorage.setItem('user', user);
            localStorage.setItem('authenticated', true);
            navigate("/home");
        } catch (e) {
            if(e.response){
                if(e.response.status === 501){
                    alert("Email taken!");
                } else {
                    alert("Failed to create user");
                    console.log("Error: ", e);
                }
            } else {
                alert("Failed to create user");
                console.log("Error: ", e);
            }
        }
        
    }


    return (
        <div className="login_page" style={{backgroundImage: `url(${IMAGES.backgrounds["room"]})`}}>
            <img src={IMAGES.logo} className="logo"/>
            <div className="login_container">
                <p className="login_text">Create Account</p>
                <form className="form_container" onSubmit={create}>
                    <input 
                        type="text" 
                        placeholder='Email...'
                        value={email}
                        className="form_option"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input 
                        type="text" 
                        placeholder='Username...'
                        value={username}
                        className="form_option"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input 
                        type="password" 
                        placeholder='Password...'
                        value={password}
                        className="form_option"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input 
                        type="password" 
                        placeholder='Re-type Password...'
                        value={repassword}
                        className="form_option"
                        onChange={(e) => setRePassword(e.target.value)}
                    />
                    <button className='submit_button' onClick={create}>Submit</button>
                </form>
                <p className='link' onClick={() => navigate('/login')}>Already have an account? Login!</p>
                <p className='link' onClick={() => navigate("/home")}>Continue as Guest</p>
            </div>
        </div>
    );
}

export default CreateAccount;