import { useEffect } from "react";

import PuffLoader from "react-spinners/PuffLoader";

import './extra.css';

const Loading = ({text}) => {

    useEffect(() => {
        var ring = document.getElementById('loader');
        var children = ring.children;
        for(const child of children){
            child.style.border = "1.25vh solid rgb(253, 230, 166)";
            child.style.width = "48.75vh";
            child.style.height = "48.75vh";
        }
    }, [])

    return (
        <div className="loading_background">
            <PuffLoader
                color={"rgb(253, 230, 166)"}
                loading={true}
                size={"50vh"}
                speedMultiplier={0.5}
                aria-label="Loading Spinner"
                data-testid="loader"
                id="loader"
            />
            <div className="loading_text">
                {text}
            </div>
        </div>
    )
}

export default Loading