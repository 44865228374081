import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import BoardSquare from './boardSquare';

import './board.css';

import {getMonsterNames} from './piece';

const Board = forwardRef(({board, myTurn, history, turnCount, gamePhase, playerLetter, sendIsland, sendMonster, monsterMoved, islandAttacked, Acolor, Bcolor, selectedPiece, selectedMonster, setSquare, setMonsters, monsterClicked, theme, submittedIslands}, ref) => {
    const [squares, setSquares] = useState([]);
    const [possibles, setPossibles] = useState([]);
    const [myIslands, setMyIslands] = useState([]);

    // The currently selected square object of the board
    const [selectedSquare, setSelectedSquare] = useState(null);
    const [newSelectedSquare, setNewSelectedSquare] = useState(null);

    useImperativeHandle(ref, () => ({
        sendYourIslands() {
          sendIslands();
        },

        sendYourMonster() {
            setMonster(selectedSquare);
            sendMonsters();
        },

        sendYourMove(){
            if(selectedSquare !== null && newSelectedSquare !== null){
                MoveMonster();
            }
        }
      }));

    useEffect(() => {
        if(history != null && history.board !== ""){
            const rets = parseBoard(history.board, Acolor, Bcolor, playerLetter);
            setSquares(rets[0]);
            setMonsters(rets[1], rets[2]);
        } else if(board.current !== ""){
            const rets = parseBoard(board, Acolor, Bcolor, playerLetter);
            setSquares(rets[0]);
            setMonsters(rets[1], rets[2]);
        }
        // console.log("reload");
    }, [board, history]);

    useEffect(() => {

    }, [squares, selectedSquare]);

    const check = (arr, x, y) => {
        return(arr[0] === x && arr[1] === y)
    };

    function getPossible(possibleMoves){
        let newSquares = [];
        squares.forEach((square) => {
            let ns = {...square};
            if(possibleMoves.some((arr) => check(arr, ns.x, ns.y))){
                ns.possible = true;
                // console.log(ns);
            } else {
                ns.possible = false;
            }
            newSquares.push(ns);
        });
        setSquares(newSquares);
    }

    // Game Functions
    function MoveMonster(){
        let newSquares = [...squares];
        const oldSquare = selectedSquare;
        const newSquare = newSelectedSquare;
        let from = oldSquare.number;
        let to = newSquare.number;
        if(newSquare.type === 0){
            swapSquares(oldSquare, newSquare);

            newSquares[oldSquare.number] = oldSquare;
            newSquares[newSquare.number] = newSquare;

            monsterMoved(boardToString(newSquares), null, from, to, false);
            setSquares(newSquares);
            setSelectedSquare(null);
            setNewSelectedSquare(null);
        } else if (newSquare.type === 1 && newSquare.id.charAt(0) !== playerLetter){
            islandAttacked();

        } else if(newSquare.type === 2 && newSquare.id.charAt(0) !== playerLetter){
            swapSquares(oldSquare, newSquare);
            newSquares[oldSquare.number] = oldSquare;
            newSquares[newSquare.number] = newSquare;
            let lost;
            if(playerLetter === 'A'){
                lost = 'B';
            } else {
                lost = 'A';
            }
            monsterMoved(boardToString(newSquares), lost, from, to);
            setSquares(newSquares);
            setSelectedSquare(null);
            setNewSelectedSquare(null);
        }
    }

    function swapSquares(oldSquare, newSquare){
        newSquare.type = 2;
        newSquare.id = oldSquare.id;
        newSquare.color = oldSquare.color;
        newSquare.monster_id = oldSquare.monster_id;

        oldSquare.type = 0;
        oldSquare.id = '0';
        oldSquare.monster_id = '0';
        oldSquare.color = 'n';
    }

    // Monster Placing Functions
    function setMonsterSquare(sq, possibleMoves){
        setSelectedSquare(sq);
        setSquare(sq);
        if(possibleMoves !== null){
            getPossible(possibleMoves);
        }
    }

    // Adding the monster/square id to the board string
    function setMonster(squareData){
        const newId = `${playerLetter}${selectedMonster.id}`;
        const newSquares = [...squares];
        const square = squares[squareData.number];
        square.id = newId;
        square.type = 2;
        square.color = 'b';
        newSquares[squareData.number] = square;
        setSquares(newSquares);
    }

    // Sending the new board with the new monster placed back to room page
    function sendMonsters(){
        sendMonster(boardToString(squares), selectedMonster.id);
        setSelectedSquare(null);
    }


    // Island Functions

    function clickIsland(squareData){
        if(!legalIsland(squareData) || submittedIslands){
            return;
        }
        if(myIslands.includes(squareData)){
            if(validAfter(squareData)){
                unsetIsland(squareData);
                // console.log("unset");
            } else {
                console.log("Remove border islands first");
            }
        } else {
            if(myIslands.length === 6) return;
            if(myIslands.length === 0 || nextTo(squareData) > 0){
                setIsland(squareData);
                // console.log("set");
            } else {
                // console.log("Islands must be connected");
            }
        }
    }

    function setIsland(squareData){
        const newId = `${playerLetter}i`;
        const newSquares = [...squares];
        const square = squares[squareData.number];
        square.id = newId;
        square.type = 1;
        square.color = 'b';
        newSquares[squareData.number] = square;
        setSquares(newSquares);
        myIslands.push(squareData);
    }

    function unsetIsland(squareData){
        const newSquares = [...squares];
        const square = squares[squareData.number];
        square.id = '0';
        square.type = 0;
        square.color = 'n';
        newSquares[squareData.number] = square;
        setSquares(newSquares);
        const idx = myIslands.indexOf(squareData);
        setMyIslands(myIslands.toSpliced(idx, 1));
    }


    function validAfter(squareData){
        let count = 0;
        const idx = myIslands.indexOf(squareData);
        const newIslands = myIslands.toSpliced(idx, 1);
        newIslands.forEach((i) => {
            count += nextToWithout(i, squareData);
        });
        if(count >= (myIslands.length - 2)*2){
            return true;
        }
        return false;
    }

    function nextTo(squareData){
        let x = squareData.x;
        let y = squareData.y;
        let count = 0;
        myIslands.forEach((island) => {
            if((x+1 === island.x || x-1 === island.x) && y === island.y){
                count += 1;
            } else if ((y+1 === island.y || y-1 === island.y) && x === island.x){
                count += 1;
            }
        }) 
        return count;
    }

    function nextToWithout(i, squareData){
        let x = i.x;
        let y = i.y;
        let count = 0;
        const idx = myIslands.indexOf(squareData);
        const newIslands = myIslands.toSpliced(idx, 1);
        newIslands.forEach((island) => {
            if((x+1 === island.x || x-1 === island.x) && y === island.y){
                count += 1;
            } else if ((y+1 === island.y || y-1 === island.y) && x === island.x){
                count += 1;
            }
        }) 
        return count;
    }

    function legalIsland(squareData){
        if(playerLetter === 'A'){
            if(squareData.number <= 139){
                return false;
            }
        } else {
            if(squareData.number > 59){
                return false;
            }
        }
        return true;
    }

    function sendIslands(){
        if(myIslands.length !== 6){
            console.log("You must have 6 tiles in your island!");
            return;
        } else {
            sendIsland(boardToString(squares));
        }
    }

    return (
        <div className="board_container">
            {playerLetter === 'A' ?
                squares.map((square) => {
                    let selected = false;
                    if((selectedSquare !== null && square.number === selectedSquare.number) || (newSelectedSquare !== null && square.number === newSelectedSquare.number)){
                        selected = true;
                    }
                    return (
                        <BoardSquare key={square.number} data={square} history={history} turnCount={turnCount} selected={selected} myTurn={myTurn} playerLetter={playerLetter} gamePhase={gamePhase} clickIsland={clickIsland} setSelectedSquare={setMonsterSquare} setNewSelectedSquare={setNewSelectedSquare} selectedPiece={selectedPiece} selectedMonster={selectedMonster} monsterClicked={monsterClicked} theme={theme}/>
                    )
                })
                :
                squares.toReversed().map((square) => {
                    let selected = false;
                    if((selectedSquare !== null && square.number === selectedSquare.number) || (newSelectedSquare !== null && square.number === newSelectedSquare.number)){
                        selected = true;
                    }
                    return (
                        <BoardSquare key={square.number} data={square} history={history} turnCount={turnCount} selected={selected} myTurn={myTurn} playerLetter={playerLetter} gamePhase={gamePhase} clickIsland={clickIsland} setSelectedSquare={setMonsterSquare} setNewSelectedSquare={setNewSelectedSquare} selectedPiece={selectedPiece} selectedMonster={selectedMonster} monsterClicked={monsterClicked} theme={theme}/>
                    )
                })
            }
            <div className="placement_line" style={{top: "26.75vh"}}/>
            <div className="placement_line" style={{top: "62.75vh"}}/>
        </div>
    );
});

export default Board;

function parseBoard(boardState, Acolor, Bcolor, playerLetter){
    // console.log(boardState);
    const rows = boardState.split('|');
    const squaresData = [];
    const mine = [];
    const opps = [];
    let row_i = 0;
    let column_i = 0;
    let number = 0;
    rows.forEach((row) => {
        const squares = row.split(',')
        column_i = 0;
        squares.forEach((square_id) => {
            let color = 'n';
            let type = 0;
            let m_id = '0';
            if(square_id !== '0'){
                // console.log(square_id);
                if(square_id.charAt(0) === 'A'){
                    color = Acolor;
                } else {
                    color = Bcolor;
                }
                if(square_id.charAt(1) === 'i'){
                    type = 1;
                } else {
                    m_id = square_id.substring(1);
                    // console.log("Type 2: ", square_id);
                    type = 2;
                }
            };
            let m_name = "";
            let url = "";
            if(type === 2){
                const out = getMonsterNames(m_id);
                // console.log(out);
                m_name = out.name;
                url = out.url;
            }
            
            const data = {
                id: square_id,
                name: m_name,
                url: url,
                mine: (square_id.charAt(0) === playerLetter),
                color: color,
                type: type,
                monster_id: m_id,
                y: row_i,
                x: column_i,
                possible: false,
                number: number,
                order: 0,
                guess: 0,
                xed: new Set()
            }
            if(data.type === 2){
                if(data.id.charAt(0) === playerLetter){
                    mine.push(data);
                } else {
                    opps.push(data);
                }
            }
            squaresData.push(data);
            column_i += 1;
            number += 1;
        })
        row_i += 1
        
    });
    return [squaresData, mine, opps];
};

function boardToString(squares){
    let str = "";
    let count = 0;
    squares.forEach((square) => {
        str += square.id;
        if(count == 199){
            
        } else if((count + 1) % 10 === 0) {
            str += '|';
        } else {
            str += ',';
        }
        count += 1;
    });
    return str;
}