export const COLORS = {
    square_hover: "rgba(255,255,255,0.5)",

    history: "rgba(237, 231, 145, 0.5)",

    light: {
        ocean: "rgb(16, 120, 168)",
        arctic: "rgb(189, 220, 235)",
        swamp: "rgb(195, 213, 207)",
        lava: "rgb(197, 211, 228)"
    },

    medium: {
        ocean: "rgb(2, 79, 123)",
        arctic: "rgb(103, 169, 207)",
        swamp: "rgb(114, 151, 136)",
        lava: "rgb(75, 84, 106)"

    },

    dark: {
        ocean: "rgb(30, 52, 100)",
        arctic: "rgb(64, 113, 138)",
        swamp: "rgb(72, 97, 84)",
        lava: "rgb(206, 72, 88)"
    },

    selected: {
        ocean: "rgb(99, 185, 224)",
        arctic: "rgb(39, 74, 92)",
        swamp: "rgb(128, 189, 164)",
        lava: "rgb(100, 111, 140)" //

    },

    possible_square: {
        ocean: "rgba(189, 234, 255, 0.7)",
        arctic: "rgba(189, 234, 255, 0.7)",
        swamp: "rgba(221, 240, 233, 0.7)",
        lava: "rgba(197, 211, 228, 0.7)"
    },

    player_colors: {
        red: "rgb(239, 71, 111)",
        orange: "rgb(247, 140, 107)",
        yellow: "rgb(255, 209, 102)",
        green: "rgb(131, 212, 131)",
        cyan: "rgb(12, 179, 169)",
        blue: "rgb(17, 138, 178)",
        dark_blue: "rgb(7, 59, 76)",
        black: "rgb(43, 43, 43)",
        purple: "rgb(171, 90, 175)",
    },

    lobby: {
        primary: "rgba(27,65,118,0.60)",
        secondary: "rgb(56, 120, 155)",
        tertiary: "rgba(27,65,118,0.75)",
    }
    
}