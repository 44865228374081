
import { useEffect, useState } from 'react';

import { ColorPicker } from './colorPicker';
import Loading from './loading';

import {ReactComponent as Send} from '../../assets/icons/Send.svg';

import './lobby.css';

import { IMAGES } from './images';
import { COLORS } from '../../colors';

const Lobby = ({roomName, myId, user, players, theme, Acolor, Bcolor, Aready, Bready, chat, readyUp, startGame, changeTheme, handleNewChat, changeMyColor}) => {
    const [colorPicker, setColorPicker] = useState(false);

    const link = `https://www.monstersofthesea.io/${roomName}`;

    const [newMessage, setNewMessage] = useState("");

    const themes = ['ocean', 'arctic', 'swamp', 'lava'];

    const [imagesLoading, setImagesLoading] = useState(true);

    let numLoaded = 0;
    const numImages = 4;
    function openColorPicker(player){
        if(player.socketId !== myId) return;
        setColorPicker(true);
    }

    useEffect(() => {
        numLoaded = 0;
    }, [])

    useEffect(() => {
        const scrollable = document.getElementById("scrollable");
        scrollable.scrollTo(0, scrollable.scrollHeight);
    }, [chat]);

    function handleChat(event){
        event.preventDefault();
        if(newMessage === "") return
        handleNewChat(newMessage);
        setNewMessage("");
    }

    function checkImages(){
        numLoaded+=1;
        console.log(numLoaded);
        if(numLoaded >= numImages){
            setImagesLoading(false);
        }
    }

    return (
        <div className="room_lobby_container" style={{backgroundImage: `url(${IMAGES.backgrounds["room"]})`, backgroundSize: "cover"}}>
            {/* {imagesLoading && <Loading text={"Loading"}/>} */}
            <div className="top_bar">
                <div className="top_bar_left">
                    <img src={IMAGES.logo} className="top_bar_logo"/>
                </div>
                <div className="top_bar_right">
                    <div className="top_bar_text">
                        <p className="top_bar_name">{user.username}</p>
                        <p className="top_bar_title">{user.title ? `${user.title}` : `Cadet`}</p>
                    </div>
                    <div className="top_bar_profile">
                        {user.profilePic ? 
                            <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                            :
                            <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                        }
                        
                    </div>
                </div>
            </div>
            <div className="sections">
                <div className="room_players_container">
                    <p className="section_title">{`Players`}</p>
                    {players.map((player) => {
                        let ready = false;
                        if(player.playerLetter === 'A'){
                            ready = Aready;
                        } else {
                            ready = Bready;
                        }

                        return (
                            <div key={player.socketId} className="player_container">
                                <div className="player_left">
                                    <div className='player_left_top'>
                                        <div className="player_pic_container">
                                            {player.profilePic ? 
                                                <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                                                :
                                                <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                                            }
                                        </div>
                                        <div className="player_text_container">
                                            <div className="player_name">
                                                {player.username}
                                            </div>
                                            <div className="player_title">
                                                {player.userTitle ? `${player.userTitle}` : `Cadet`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lobby_ready_text">
                                       {ready ? "Ready" : "Not Ready"} 
                                    </div>
                                </div>
                                <div className="player_right">
                                    <div className="player_color" style={player.playerLetter === 'A' ? {backgroundColor: COLORS.player_colors[Acolor]} : {backgroundColor: COLORS.player_colors[Bcolor]}} onClick={() => openColorPicker(player)}/>
                                </div>                    
                            </div>
                        );
                    })}
                </div>
                <div className="room_settings_container">
                    <p className="section_title">Game Settings</p>
                    <div className="room_settings_middle_container">
                        <div className='room_link_container'>
                            <p className="room_map_text">Room Link:</p>
                            <p className="room_link_text">{link}</p>
                            <button className="room_copy_button" onClick={() => navigator.clipboard.writeText(link)}>Copy</button>
                        </div>
                        <div className='room_settings_options'>

                        </div>
                        <div className='room_map_container'>
                            <p className='room_map_text'>Choose Map</p>
                            <div className='room_map_options'>
                                {themes.map((t) => {
                                    return (
                                        (theme===t) ?
                                        <div key={t} className='room_map_option' onClick={() => changeTheme(t)} style={{backgroundImage: `url(${IMAGES.backgrounds[t]})`, border: `solid 0.5vh ${COLORS.selected['ocean']}`}}/>
                                        :
                                        <div key={t} className='room_map_option' onClick={() => changeTheme(t)} style={{backgroundImage: `url(${IMAGES.backgrounds[t]})`}}/>
                                    );
                                    
                                })}
                            </div>
                        </div>

                    </div>
                    <div className="lobby_bottom_buttons">
                        <button className="lobby_bottom_button" onClick={readyUp}>Ready</button>
                        <button className="lobby_bottom_button" onClick={startGame}>Start Game</button>
                    </div>
                </div>
                <div className="room_chat_container">
                    <p className="section_title">Chat</p>
                    <div className='room_chat_inside'>
                        <div id="scrollable" className="chat_scroll_container">
                            <div className='chat_scroll_inside'>
                                {chat.map((message) => {
                                    return (
                                        <div key={message.id} className="message_container">
                                            <p className="message">{`(${message.username}): ${message.content}`}</p>
                                            {/* <p className="message_username">{message.username + ": "}</p>
                                            <p className="message_content">{message.content}</p> */}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <form className="new_chat_container" onSubmit={handleChat}>
                            <input
                                type="text"
                                value={newMessage}
                                placeholder='Message...'
                                onChange={(e) => setNewMessage(e.target.value)}
                                className="new_chat_input"
                            />
                            <Send type="submit" className="new_chat_submit" onClick={handleChat}/>
                        </form>
                    </div>
                </div>
            </div>
            {colorPicker && <ColorPicker closeModal={setColorPicker} onConfirm={changeMyColor} Acolor={Acolor} Bcolor={Bcolor}/>}  
        </div>
    )
}

export default Lobby