import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import HomePage from './pages/HomePage';
import RoomPage from './pages/RoomPage';
import LoginPage from './pages/LoginPage';
import AuthWrapper from './pages/AuthWrapper';
import CreateAccount from './pages/CreateAccount';
import ProfilePage from './pages/ProfilePage';

import { BrowserRouter } from "react-router-dom";
import {Route, Routes, Navigate } from "react-router-dom";

const App = () => {

  return (
  <BrowserRouter>
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/createaccount" element={<CreateAccount />} />
        {/* <Route element={<AuthWrapper />}> */}
          <Route path="/" element={<Navigate to="/home"/>} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage/>} />
          <Route path="/:roomid" element={<RoomPage />} />
        {/* </Route> */}
        
        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </>
  </BrowserRouter>
  );
}
  
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);