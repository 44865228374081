import RandExp from 'randexp';

export class User {
    constructor(id, guest){
        this.id = id;
        if(this.id === 0){
            this.username = "Guest#"+new RandExp(/([a-zA-Z0-9]{5})/).gen();
            this.guest = guest;
        }
    }
}