import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import RandExp from 'randexp';

import secureLocalStorage from "react-secure-storage";

import { socket } from '../socket.js';

import Loading from '../game/model/loading.js';

import './home.css';
import { useNavigate } from 'react-router-dom';
import { IMAGES } from '../game/model/images.js';
import { User } from '../game/model/user.js';

import {ReactComponent as Send} from '../assets/icons/Send.svg';

const HomePage = () => {
    // const socket = io.connect(`https://api.monstersofthesea.io`);
    // const socket = io.connect("http://localhost:9000/");

    const [showPlay, setShowPlay] = useState(false);

    const [showProfile, setShowProfile] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [user, setUser] = useState(null);

    const navigate = useNavigate();

    const [imagesLoading, setImagesLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const images = IMAGES.home;
    let numLoaded = 0;
    const numImages = Object.keys(images).length;

    useEffect(() => {
        numLoaded = 0;
        getUser();

        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener("resize", handleWindowResize);

        const handleUnload = () => {
            console.log("Unload");
            socket.disconnect();
            window.removeEventListener("resize", handleWindowResize);
            if(user && user.guest){
                secureLocalStorage.removeItem("user");
            }
        }
        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener("beforeunload", handleUnload);
            if(user && user.guest){
                secureLocalStorage.removeItem("user");
            }
        }
    }, []);

    useEffect(() => {
        checkLoading();
    }, [imagesLoading, userLoading])

    async function getUser(){
        let stored_user = secureLocalStorage.getItem("user");
        // console.log(stored_user);
        if(stored_user && !stored_user.guest){
            setUser(stored_user);
        } else {
            let new_user = new User(0, true);
            setUser(new_user);
            secureLocalStorage.setItem("user", new_user);
        }
        setIsLoading(false);
    }

    function checkImages(){
        numLoaded+=1;
        // console.log(numLoaded);
        if(numLoaded >= numImages){
            setImagesLoading(false);
        }
    }

    function checkLoading(){
        if(!imagesLoading && !userLoading){
            setIsLoading(false);
        }
    }

    function joinRoom(roomName) {
        navigate(`/${roomName}`, { state: { user: user, roomName: roomName}});
        socket.disconnect();
    }

    function createRoom(botGame) {
        let roomName = new RandExp(/([a-zA-Z0-9]{5})/).gen();
        // console.log("Socket: ", socket);
        socket.connect();
        socket.emit("create room", {roomName, botGame}, (response) => {
            if(!response){
                alert("Failed to create room");
            } else {
                navigate(`/${roomName}`, { state: { user: user, roomName: roomName}});
                socket.disconnect();
            }
        });
    }

    function handlePlay(){
        setShowPlay(true);
    }

    function handleProfileClick() {
        if(user && user.guest){
            navigate("/login");
        } else {
            setShowProfile(!showProfile);
        }
    }

    function handleLogout() {
        if(user && user.guest) return;
        let new_user = new User(0, true);
        setUser(new_user);
        secureLocalStorage.setItem("user", new_user);
        navigate('/login');
    }

    if(isLoading) {
        return (
            <Loading text={"Loading"}/>
        )
    }

    return ReactDOM.createPortal (
        <div className="main_container">
            {imagesLoading && <Loading text={"Loading"}/>}
            <img 
                className="front_waves"
                src={images['front_water.png']}
                onLoad={checkImages}
            />
            <div className='monster_container'>
                <img
                    className="monster"
                    src={images['sea_monster.png']}
                    onLoad={checkImages}
                />
            </div>
            
            <img
                className="mountain"
                src={images['mountain.png']}
                onLoad={checkImages}
            />
            <img
                className="ship"
                src={images['ship.png']}
                onLoad={checkImages}
            />
            <img
                className="right_waves"
                src={images['blue_background_waves.png']}
                onLoad={checkImages}
            />
            <img
                className="blue_purple"
                src={images['navy_purple.png']}
                onLoad={checkImages}
            />

            <img
                className="side_waves"
                src={images['side_waves.png']}
                onLoad={checkImages}
            />
            <img
                className="cream"
                src={images['cream.png']}
                onLoad={checkImages}
            />
            <img
                className="title"
                src={images['title.png']}
                onLoad={checkImages}
            />

            <img
                className="particles"
                src={images['particles.png']}
                onLoad={checkImages}
            />

            <div 
                className="side_bar"
                style={{right: 0}}
            />

            <div 
                className="side_bar"
                style={{left: 0}}
            />
            
            <div className="bottom_buttons">

                {(width/height) > (80/55) && 
                <div className="button_container">
                    <button className='button'>Tutorial</button>
                </div>
                }
                <div className="button_container">
                    <button className='button' onClick={handlePlay}>Play</button>
                </div>
                
                {(width/height) <= (80/55) && 
                <div className="button_container">
                    <button className='button'>Tutorial</button>
                </div>
                }
                <div className="button_container">
                    <button className='button'>Settings</button>
                </div>
            </div>
            {showPlay && <PlayOptions closeModal={setShowPlay} createRoom={createRoom} joinRoom={joinRoom}/>}
            {/* <div className="screenSize">
                {`Width: ${width}px`}
                <br/>
                {`Height: ${height}px`}
            </div> */}
            <div className='home_top_right'>
                <p className='home_top_name'>{user.username}</p>
                <div className="profile_icon_container">
                    <div className="profile_icon" onClick={handleProfileClick}/>
                </div>
                {showProfile && <div className='profile_options' onMouseLeave={() => setShowProfile(false)}>
                    <div className='profile_option' onClick={() => navigate("/profile")}>Profile</div>
                    <div className='profile_option' onClick={handleLogout}>Logout</div>
                </div>}
            </div>
            
        </div>
        ,document.body
    );
}

export default HomePage;

export const PlayOptions = ({closeModal, createRoom, joinRoom}) => {
    const [roomName, setRoomName] = useState("");

    const [enterCode, setEnterCode] = useState(false);

    function handleOutsideClick(event) {
        if(event.target.id && event.target.id === "outside"){
            closeModal(false);
        }
    }

    return (
        <div id='outside' className="home_center_container" style={{backgroundColor: "rgba(255, 237, 105, 0.25)"}} onClick={handleOutsideClick}>
            <div className="play_options_container">
                <div className='play_option_container'>
                    <div className="play_option">
                        <div className="button_container">
                            <button className='button' onClick={() => createRoom(false)}>Create Room</button>
                        </div>
                    </div>
                </div>
                <div className='play_option_container'>
                    {enterCode ? 
                        <div className="play_option" onMouseLeave={() => setEnterCode(false)}>
                            <form className="enter_code_container" onSubmit={() => joinRoom(roomName)}>
                                <input 
                                    type="text" 
                                    placeholder='Enter Room Code...'
                                    value={roomName}
                                    className="roomname_container"
                                    onChange={(e) => setRoomName(e.target.value)}
                                />
                                <Send type="submit" className="enter_room_submit" onClick={() => joinRoom(roomName)}/>
                            </form>
                        </div>
                        :
                        <div className="play_option" >
                            <div className="button_container">
                                <button className='button' onClick={() => setEnterCode(true)}>Join Room</button>
                            </div>
                        </div>
                    }
                </div>
                <div className='play_option_container'>
                    <div className="play_option">
                        <div className="button_container">
                            <button className='button' onClick={() => createRoom(true)}>Play VS Bots</button>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
}

export const RoomCode = ({closeModal, joinRoom}) => {
    const [roomName, setRoomName] = useState("");

    function handleOutsideClick(event) {
        if(event.target.id && event.target.id === "outside"){
            closeModal(false);
        }
    }

    return (
        <div id='outside' className="home_center_container" onClick={handleOutsideClick}>
            <form className="play_option" onSubmit={() => joinRoom(roomName)}>
                <p className="enter_code_text">Enter Room Code:</p>
                <input 
                    type="text" 
                    value={roomName}
                    className="roomname_container"
                    onChange={(e) => setRoomName(e.target.value)}
                />     
                <div className="button_container">
                    <button className='button' onClick={() => joinRoom(roomName)}>Join Room</button>
                </div>
            </form>
        </div>
    );
}