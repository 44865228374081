import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import secureLocalStorage from "react-secure-storage";

import Loading from '../game/model/loading.js';

import './profile.css';

import { IMAGES } from '../game/model/images';
import { COLORS } from '../colors.js';
import Settings from '../game/settings.js';
import Stats from '../game/stats.js';

import ItemService from '../services/item.service.js';

const ProfilePage = () => {
    const [user, setUser] = useState(null);

    const [viewing, setViewing] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const options = [{id: 0, title: 'Account Settings'}, {id: 1, title: 'Game Statistics'}, {id: 2, title: 'Cosmetics'}];

    const navigate = useNavigate();

    useEffect(() => {
        if(!user){
            getUser();
            return;
        }

    }, []);

    async function getUser(){
        let stored_user = secureLocalStorage.getItem("user");
        
        // console.log(stored_user);
        try {
            const response = await ItemService.getByType({userId: stored_user.id, type: 1})
            // console.log(response.data);
            stored_user.ownedTitles = response.data;
        } catch(e){
            console.log(e)
        }
        setUser(stored_user);
        setIsLoading(false);
    }

    if(isLoading) {
        return (
            <Loading text={"Loading..."}/>
        );
    }

    function getDisplay(){
        switch(viewing){
            case 0:
                return <Settings user={user} getUser={getUser}/>
            case 1:
                return <Stats user={user}/>
            default:
                return <Settings user={user} getUser={getUser}/>
        }
    }

    return (
        <div className="profile_page_container" style={{backgroundImage: `url(${IMAGES.backgrounds["room"]})`}}>
            <div className="profile_top_bar">
                <button className="profile_button" onClick={() => navigate('/home')}>Back</button>
            </div>
            <div className="profile_main_container">
                <div className="user_info_container">
                    <div className="profile_title">{` - ${user.title} - `}</div>
                    <div className="profile_picture">
                        {user.profilePic ? 
                            <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                            :
                            <img src={IMAGES.profile_pics['default.png']} className="profile_pic"/>
                        }
                    </div>
                    <div className="profile_name">{user.username}</div>
                    <div className="profile_rank">Admiral</div>
                </div>
                <div className="profile_outside_options_container">
                    <div className='profile_display_options_container'>
                        {options.map((option) => {
                            return (
                                <div className='profile_display_option' onClick={() => setViewing(option.id)} style={viewing === option.id ? {backgroundColor: COLORS.lobby.primary} : {backgroundColor: COLORS.lobby.secondary}}>
                                    {option.title}
                                </div>
                            )
                        })}
                    </div>
                    {getDisplay()}
                </div>
            </div>

        </div>
    )
}

export default ProfilePage;