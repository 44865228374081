import axios from 'axios';
import {url} from '../utils/constants.js';

const statsBaseURL = `${url}/api/stats`;

class StatsService {

  get(id, type, order, wincon, monsters) {
    // console.log("Id: ", id);
    // console.log("Type: ", type);
    //   console.log("Order: ", order);
    //   console.log("Wincon: ", wincon);
    return axios.get(statsBaseURL, {
      params: {
        'id': id,
        'type': type,
        'order': order,
        'wincon': wincon,
        'monsters': monsters
      }
    });
  }

}

export default new StatsService();