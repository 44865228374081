import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import secureLocalStorage from "react-secure-storage";

import UserService from '../services/user.service.js';

import Loading from '../game/model/loading.js';
import Confirm from './model/confirm.js';

import '../pages/profile.css';

import { IMAGES } from '../game/model/images';

const Settings = ({user, getUser}) => {

    const [newUsername, setNewUsername] = useState("");
    const [newMusic, setNewMusic] = useState(user.music);
    const [newSFX, setNewSFX] = useState(user.sfx);
    const [newTitle, setNewTitle] = useState("");

    const [confirmModal, setConfirmModal] = useState(false);

    const deleteTitle = "Are you sure?"
    const deleteBody = "Are you sure you want to delete your account? All data associated with your account, including game data, purchases, and achievements will be lost. This action cannot be undone. Do you wish to proceed?"

    const navigate = useNavigate();

    useEffect(() => {

    }, []);

    async function handleChange(event){
        event.preventDefault();

        const data = {}
        if(user.username !== newUsername && newUsername !== "") data.username = newUsername;
        if(user.music !== newMusic) data.music = newMusic;
        if(user.sfx !== newSFX) data.sfx = newSFX;
        if(user.title !== newTitle && newTitle !== "") data.title = newTitle;

        console.log("data: ", data);
        try{
            if(Object.keys(data).length > 0){
                const response = await UserService.update(user.id, data);
                console.log("Updated: ", response.data);
                const newUser = response.data;
                newUser.guest = false;
                secureLocalStorage.setItem("user", newUser);
                getUser();
            }
        } catch(e) {
            console.log(e);
        }
    }

    async function handleConfirmDelete(){
        try {
            const response = await UserService.delete(user.id);
            console.log("Account deleted");
            localStorage.setItem('authenticated', false);
            secureLocalStorage.removeItem('user');
            navigate('/login');
        } catch(e) {
            console.log(e);
            alert('Could not delete account at this time');
        }
    }

    return (
        <div className="settings_container">
            <form className="settings_options" onSubmit={handleChange}>
                <div className='settings_option'>
                    <label className='settings_label'>Email:</label>
                    <input 
                        type="text" 
                        placeholder={`${user.email}`}
                        className="form_option"
                        readOnly
                    />
                </div>
                <div className='settings_option'>
                    <label className='settings_label'>Username:</label>
                    <input 
                        type="text" 
                        placeholder={user.username}
                        value={newUsername}
                        className="form_option"
                        onChange={(e) => setNewUsername(e.target.value)}
                    />
                </div>
                <div className='settings_option'>
                    <label className='settings_label'>Music:</label>
                    <input 
                        type="checkbox" 
                        value={newMusic}
                        className="form_option"
                        defaultChecked={user.music}
                        onChange={(e) => setNewMusic(e.target.checked)}
                    />
                </div>
                <div className='settings_option'>
                    <label className='settings_label'>Sound Effects:</label>
                    <input 
                        type="checkbox" 
                        value={newSFX}
                        className="form_option"
                        defaultChecked={user.sfx}
                        onChange={(e) => setNewSFX(e.target.checked)}
                    />
                </div>
                <div className='settings_option'>
                    <label className='settings_label'>Title:</label>
                    <select 
                        // value={newTitle}
                        defaultValue={user.title}
                        className="form_option"
                        onChange={(e) => setNewTitle(e.target.value)}
                    >
                        {user.ownedTitles.map((item) => {
                            return (
                                <option key={item.id}>{item.text}</option>
                            )
                        })}
                    </select>
                </div>
                
            </form>
            <button className='profile_button' onClick={() => navigate('/login')}>Log Out</button>
            <button className='profile_button' onClick={() => setConfirmModal(true)}>Delete Account</button>
            <div className='settings_bottom'>
                <button className='profile_button' onClick={handleChange}>Save Changes</button>
            </div>
            {confirmModal && <Confirm title={deleteTitle} text={deleteBody} onConfirm={handleConfirmDelete} closeModal={setConfirmModal}/>}
        </div>
    )
}

export default Settings;