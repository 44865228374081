import axios from 'axios';
import {url} from '../utils/constants.js';

const itemBaseURL = `${url}/api/items`;

class ItemService {

  getByType(userId, type) {
    return axios.get(itemBaseURL, {
        params: {
            userId: userId,
            type: type
        }
    });
  }

}

export default new ItemService();