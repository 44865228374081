import React, { useState, useEffect } from 'react';

import { getPiece, monsterList } from './piece';

import { COLORS } from '../../colors';
import { IMAGES } from './images';

import './board.css';

const BoardSquare = ({data, history, turnCount, selected, myTurn, playerLetter, gamePhase, clickIsland, setSelectedSquare, selectedPiece, selectedMonster, setNewSelectedSquare, monsterClicked, theme}) => {
    const [color, setColor] = useState("white");
    const [neg, setNeg] = useState(1);

    useEffect(() => {
        let c = "white";
        // console.log(data);
        if(data.color !== 'n'){
            c = data.color;
        }

        if(data.possible){
            c = COLORS.selected[theme];
        }

        if(playerLetter === 'B'){
            setNeg(-1);
        }

        setColor(c); 
    }, [data]);

    useEffect(() => {
        if(gamePhase === 2 && selected){
            if(selectedMonster !== null && (!('mine' in selectedMonster) || selectedMonster.mine)){
                const p = getPiece(data, selectedMonster.id, color);
                const ps = p.getPossibleMoves(neg); 
                setSelectedSquare(data, ps);
            }
        }
    }, [selectedMonster]);

    function handleClick(){
        switch(gamePhase){
            case 1:
                clickIsland(data);
                break;
            case 2:
                if(myTurn && data.type === 0 && legalSquare()){
                    // console.log(selectedPiece);
                    if(selectedMonster !== null && (!('mine' in selectedMonster) || selectedMonster.mine)){
                        const p = getPiece(data, selectedMonster.id, color);
                        const ps = p.getPossibleMoves(neg); 
                        setSelectedSquare(data, ps);
                    }
                }
                break;
            case 3:
                if(myTurn && (history === null || turnCount === history.turn)){
                    if(data.type === 2 && data.id.charAt(0) === playerLetter){
                        const mid = parseInt(data.id.substring(1));
                        const p = getPiece(data, mid, color);
                        const ps = p.getPossibleMoves(neg); 
                        setSelectedSquare(data, ps);
                        setNewSelectedSquare(null);
                    } else if(data.possible){
                        // console.log("Selected: ", selectedSquare)
                        setNewSelectedSquare(data);
                    }

                } else {
                    if(data.type === 2 && data.id.charAt(0) === playerLetter){
                        //Display monster info
                    }
                }
        };

        if(data.type === 2 && gamePhase !== 2){
            monsterClicked(data, data.id.charAt(0) === playerLetter);
        }
    }

    function legalSquare(){
        if(playerLetter === 'A'){
            if(data.number <= 139){
                return false;
            }
        } else {
            if(data.number > 59){
                return false;
            }
        }
        return true;
    }

    function getStyle(){

        if(data.type === 1){
            if(selected){
                return {backgroundColor: COLORS.light[theme], borderColor: COLORS.selected[theme], borderWidth: "0.5vh"};
            } else if(data.possible){
                return {backgroundColor: COLORS.light[theme], borderColor: COLORS.medium[theme], borderWidth: "0.5vh"};
            } else {
                return {backgroundColor: COLORS.light[theme], borderColor: COLORS.light[theme], borderWidth: "0.25vh"};
            }
        }

        

        if(data.possible && selected){
            return {backgroundColor: COLORS.selected[theme]};
        }

        if(data.possible){
            return {backgroundColor: COLORS.possible_square[theme]};
        }

        if(history && (history.to === data.number || history.from === data.number)){
            return {backgroundColor: COLORS.history};
        }

        return {}
    }

    return (
        <div className="square_container" style={getStyle()} onClick={handleClick}>
            {(data.type === 2) && 
                <div className="piece_image_container" style={(selectedPiece && data.id === selectedPiece.id) ? {borderColor: COLORS.selected[theme]} : {borderColor: COLORS.player_colors[data.color]}}>
                    {data.mine ?
                        <div className="piece_background">
                            <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${data.url}_game.png`]})`}} className="piece_image"/>
                        </div>
                        :
                        <div className="piece_background">
                            {data.guess === 0 ?
                                <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`general_game.png`]})`}} className="piece_image"/>
                                :
                                <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${monsterList[data.guess - 1].url}_game.png`]})`}} className="piece_image"/>
                            }
                        </div>
                    }
                    
                </div>
            }
            {(gamePhase === 2 && selected) &&
                <div className="piece_image_container" style={{borderColor: COLORS.selected[theme]}}>
                    <div className="piece_background">
                        <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${selectedMonster.url}_game.png`]})`}} className="piece_image"/>
                    </div>
                </div>
            }
        </div>
    );
}



export default BoardSquare;