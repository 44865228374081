
import logo from '../../assets/home/title.png';

const backgrounds = importAll(require.context('../../assets/backgrounds', false, /\.(png|jpe?g|svg)$/));
const tab_buttons = importAll(require.context('../../assets/tab_buttons', false, /\.(png|jpe?g|svg)$/));

const ocean_monsters = importAll(require.context('../../assets/monsters/ocean', false, /\.(png|jpe?g|svg)$/));
const arctic_monsters = importAll(require.context('../../assets/monsters/arctic', false, /\.(png|jpe?g|svg)$/));
const swamp_monsters = importAll(require.context('../../assets/monsters/swamp', false, /\.(png|jpe?g|svg)$/));
const lava_monsters = importAll(require.context('../../assets/monsters/lava', false, /\.(png|jpe?g|svg)$/));

const home_images = importAll(require.context('../../assets/home', false, /\.(png|jpe?g|svg)$/));

const profile_pics = importAll(require.context('../../assets/profile_pics', false, /\.(png|jpe?g|svg)$/));

export const IMAGES = {
    backgrounds: {
        ocean: backgrounds[`ocean_background.png`],
        arctic: backgrounds[`arctic_background.png`],
        swamp: backgrounds[`swamp_background.png`],
        lava: backgrounds[`lava_background.png`],
        room: backgrounds[`room_background.png`],
    },

    monsters: {
        ocean: ocean_monsters,
        arctic: arctic_monsters,
        swamp: swamp_monsters,
        lava: lava_monsters,
    },

    tab_button: {
        ocean: tab_buttons[`tab_button_ocean.png`],
        arctic: tab_buttons[`tab_button_arctic.png`],
        swamp: tab_buttons[`tab_button_swamp.png`],
        lava: tab_buttons[`tab_button_lava.png`],
    },

    logo: logo,
    home: home_images,
    profile_pics: profile_pics,
    
}

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item);});
	return images
}