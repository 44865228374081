import { useState } from "react";
import ReactDOM from 'react-dom';

import { COLORS } from "../../colors";

export const ColorPicker = ({closeModal, onConfirm, Acolor, Bcolor}) => {
    const colorOptions = ["red", "orange", "yellow", "green", "cyan", "blue", "dark_blue", "black", "purple"];

    const [selected, setSelected] = useState("");

    function handleClick(color){
        if(selected === color){
            setSelected("");
        } else {
            setSelected(color);
        }
    }

    function handleSubmit(){
        if(selected === null || selected === "") {
            return;
        }
        onConfirm(selected);
        closeModal(false);
    }

    function handleCancel(){
        closeModal(false);
    }

    function getStyle(color){
        if(selected === color){
            return {backgroundColor: COLORS.player_colors[color], borderColor: COLORS.selected['ocean']}
        } else {
            return {backgroundColor: COLORS.player_colors[color]}
        }
    }

    return ReactDOM.createPortal(
        <div className="color_picker_background">
            <div className="color_picker_container">
                <p className="color_picker_title">Choose your Color!</p>
                <div className="color_picker_options">
                    {colorOptions.map((color) => {
                        if(color !== Acolor && color !== Bcolor){
                            return (
                                <div key={color} className="color_option" style={getStyle(color)} onClick={() => handleClick(color)}/>
                            );
                        }
                    })}
                </div>
                <div className="color_picker_buttons">
                    <button className="color_picker_button" onClick={handleCancel}>Cancel</button>
                    <button className="color_picker_button" onClick={handleSubmit}>Submit</button>
                </div>
            </div>
        </div>
        ,document.body
    )
}