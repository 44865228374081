
import { useEffect, useState } from 'react';

import { monsterList } from './piece';
import { IMAGES } from './images';

import './turn_info.css';

const MonsterOptions = ({chosen, setMonster, myTurn, onSubmit, theme}) => {
    
    const [selected, setSelected] = useState(null);
    const [hovered, setHovered] = useState(null);

    useEffect(() => {

    }, [chosen]);

    function handleClick(monster){
        if(!myTurn) return;
        if(containsThis(monster.id)) return;

        setMonster(monster);
        setSelected(monster);
    }

    function handleHover(monster){
        setHovered(monster);
    }

    function handleUnHover() {
        setHovered(null);
    }

    function containsThis(id){
        return chosen.some(m => parseInt(m.monster_id) === id);
    }

    function getStyle(monster){
        const opacity = (hovered && hovered.id === monster.id) ? "0.75" : "1";

        if(containsThis(monster.id)){
            return {
                filter: "brightness(0.5)",
                opacity: opacity,
            };
        }

        if(selected && selected.id === monster.id){
            return {
                filter: "brightness(1.5)",
                opacity: opacity,
            };
        }

        return {
            filter: "brightness(1)",
            opacity: opacity,
        };
    }

    return (
        <div className="monster_option_container">
            <div className="monster_option_title">
                <p className="monster_option_title_text">{myTurn ? "Select a monster to place." : "Waiting for Opponent to place"}</p>
            </div>
            <div className="monster_option_body">
                {monsterList.map((monster) => {
                    return (
                        <div className="monster_option_square_container" key={monster.id} onClick={() => handleClick(monster)} onMouseEnter={() => handleHover(monster)} onMouseLeave={handleUnHover}>
                            <div className="monster_option_image_container" style={getStyle(monster)}>
                                <div className="monster_option_background">
                                    <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${monster.url}_side.png`]})`}} className="monster_option_image"/>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {/* <button className="monster_option_submit" onClick={onSubmit}>Submit</button> */}
        </div>
    )
}

export default MonsterOptions