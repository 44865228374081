import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import secureLocalStorage from "react-secure-storage";

import StatsService from '../services/stats.service.js';

import {ReactComponent as Down} from '../assets/icons/Down2.svg';

import Loading from '../game/model/loading.js';

import '../pages/profile.css';

import { IMAGES } from '../game/model/images';

const Stats = ({user}) => {
    const [monsters, setMonsters] = useState([]);
    const [wentFirst, setWentFirst] = useState(0);
    const [gameFinished, setFinished] = useState(0);
    const [winCon, setWinCon] = useState(0);
    const [gameType, setType] = useState(0);

    const [gamesPlayed, setGamesPlayed] = useState(0);
    const [gamesWon, setGamesWon] = useState(0);
    const [winPercent, setWinsPercent] = useState(0);

    const [monsterDrop, setMonsterDrop] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const monsterIds = [
        {value: 1, label: 'Leviathan', selected: false},
        {value: 2, label: 'Sea Hydra', selected: false},
        {value: 3, label: 'Kraken', selected: false},
        {value: 4, label: 'White Whale', selected: false},
        {value: 5, label: 'Megalodon', selected: false},
        {value: 6, label: 'Siren', selected: false},
        {value: 7, label: 'Giant Squid', selected: false},
        {value: 8, label: 'Ebirah', selected: false},
        {value: 9, label: 'Jormungandr', selected: false},
        {value: 10, label: 'Loch Ness', selected: false},
    ]; 
    const navigate = useNavigate();

    useEffect(() => {
        getStats()
    }, []);

    async function handleSubmit(event){
        event.preventDefault()
        event.stopPropagation();

        console.log("Monsters: ", monsters);

        try{
            const response = await StatsService.get(user.id, gameType, wentFirst, winCon, monsters);
            setGamesPlayed(response.data.total);
            setGamesWon(response.data.wins);
            if(response.data.total === '0'){
                setWinsPercent(0);
            } else {
                setWinsPercent(Math.round((response.data.wins/response.data.total)*100));
            }
            setIsLoading(false);
        } catch(e) {
            console.log(e);
        }
    }

    async function handleMonsterClick(monster){
        // console.log(monster);
        const idx = monsters.indexOf(monster.value);
        const newArray = monsters;
        if(idx === -1){
            newArray.push(monster.value);
            monsterIds[monster.value - 1].selected = true;
        } else {
            newArray.splice(idx, 1);
            monsterIds[monster.value - 1].selected = false;
        }
        setMonsters(newArray);
    }

    async function getStats(){
        try {
            const response = await StatsService.get(user.id, gameType, wentFirst, winCon);
            // console.log(response.data);
            setGamesPlayed(response.data.total);
            setGamesWon(response.data.wins);
            if(response.data.total === '0'){
                setWinsPercent(0);
            } else {
                setWinsPercent(Math.round((response.data.wins/response.data.total)*100));
            }
            setIsLoading(false);
        } catch(e){
            console.log(e)
        }
    }

    async function handleDrop(event) {
        event.stopPropagation();

        console.log(event.target);
        setMonsterDrop(!monsterDrop)
    }



    return (
        <div className="settings_container">
            <div className="stats_options_container">
                <form className='stats_form' onSubmit={handleSubmit}>
                    <div className='stats_option'>
                        <label className='stats_label'>Type:</label>
                        <select 
                            defaultValue={0}
                            className="stat_option_options"
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value={0}>- - -</option>
                            <option value={1}>Casual</option>
                            <option value={2}>Ranked</option>
                        </select>
                    </div>
                    <div className='stats_option'>
                        <label className='stats_label'>Play Order:</label>
                        <select 
                            defaultValue={0}
                            className="stat_option_options"
                            onChange={(e) => setWentFirst(e.target.value)}
                        >
                            <option value={0}>- - -</option>
                            <option value={1}>First</option>
                            <option value={2}>Second</option>
                        </select>
                    </div>
                    <div className='stats_option'>
                        <label className='stats_label'>Win Con:</label>
                        <select 
                            defaultValue={0}
                            className="stat_option_options"
                            onChange={(e) => setWinCon(e.target.value)}
                        >
                            <option value={0} className='stat_option_option'>- - -</option>
                            <option value={1} className='stat_option_option'>Islands</option>
                            <option value={2} className='stat_option_option'>Monsters</option>
                        </select>
                    </div>
                </form>
                <div className='stats_option'>
                    <label className='stats_label'>Monsters:</label>
                    <div className='stat_option_options'>
                        <div className='stats_option_inner' onClick={handleDrop}>
                            <p style={{margin: 0}}>Select Monsters</p>
                            <Down className="down_icon"/>
                        </div>
                        {monsterDrop && 
                        <form className='monster_drop_container' onMouseLeave={() => setMonsterDrop(false)}>
                            {monsterIds.map((monster) => {
                                return (
                                    <div key={monster.value} className='monster_drop_option'>
                                        <input 
                                            type="checkbox" 
                                            id={monster.label} 
                                            value={monsterIds[monster.value - 1].selected} 
                                            onChange={() => handleMonsterClick(monster)}
                                            defaultChecked={monsters.includes(monster.value)}
                                        />
                                        <p style={{margin: 0}}>{monster.label}</p>
                                    </div>       
                                )
                            })}
                        </form>
                        }
                    </div> 
                </div>
            </div>
            <div className='stats_display_container'>
                <p className='stats_text' style={{fontSize: "5vh"}}>{`Win Percentage: ${winPercent}%`}</p>
                <p className='stats_text' style={{fontSize: "4vh"}}>{`Games Played: ${gamesPlayed}`}</p>
                <p className='stats_text' style={{fontSize: "4vh"}}>{`Games Won: ${gamesWon}`}</p>

            </div>
            <div className='settings_bottom'>
                <button className='profile_button' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    )
}

export default Stats;