import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import secureLocalStorage from "react-secure-storage";

import Board from '../game/model/board';
import Loading from '../game/model/loading.js';
import MonsterOptions from '../game/model/monsterOptions';
import Lobby from '../game/model/lobby.js';

import { socket } from '../socket.js';

import './room.css';
import './room_lobby.css';

import { User } from '../game/model/user.js';
import { monsterList, getMonsterNames } from '../game/model/piece';

import { COLORS } from '../colors';
import { IMAGES } from '../game/model/images.js';

import {ReactComponent as Left} from '../assets/icons/Left.svg';
import {ReactComponent as Right} from '../assets/icons/Right.svg';
import {ReactComponent as Up} from '../assets/icons/Up.svg';
import {ReactComponent as Down} from '../assets/icons/Down.svg';
import {ReactComponent as Send} from '../assets/icons/Send.svg';
import {ReactComponent as Chat} from '../assets/icons/Chat.svg';

const RoomPage = () => {
    const roomName = window.location.pathname.slice(1);

    const [user, setUser] = useState(null);

    const [theme, setTheme] = useState("ocean");

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [players, setPlayers] = useState([]);
    const [board, setBoard] = useState("");
    const [submittedIslands, setSubmittedIslands] = useState(false);
    const [winner, setWinner] = useState(null);


    // Timer for when a player disconnects, boolean for if the timer should be counting down
    const [timer, setTimer] = useState(false);
    const [seconds, setSeconds] = useState(60);

    //Monsters Lists
    const [myMonsters, setMyMonsters] = useState([]);
    const [oppMonsters, setOppMonsters] = useState([]);

    // Monster game piece selected
    const [selectedMonster, setSelectedMonster] = useState(null);

    // Current square selected
    const [selectedSquare, setSelectedSquare] = useState(null);

    // Monster being displayed on left bar
    const [viewingMonster, setViewingMonster] = useState(null);

    // Whether left modal is expanded or not
    const [leftModal, setLeftModal] = useState(true);

    // Whether chat modal is expanded or not
    const [chatModal, setChatModal] = useState(true);

    const [chat, setChat] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    

    const gamePhase = useRef(0);
    const myId = useRef(null);
    const playerLetter = useRef("");
    const myTurn = useRef(false);
    const botGame = useRef(false);

    const Acolor = useRef("");
    const Bcolor = useRef("");
    const Aready = useRef(false);
    const Bready = useRef(false);
    const privateLobby = useRef(false);

    const turnCount = useRef(0);
    const [history, setHistory] = useState(null);
    const viewingTurn = useRef(0);

    const gameStyle = useRef("ocean");

    const boardRef = useRef(null);

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(!user){
            getUser();
            return;
        }

        socket.connect();

        const handleWindowResize = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener("resize", handleWindowResize);

        const leaveRoom = () => {
            // console.log("Leave");
            socket.disconnect();
            window.removeEventListener("popstate", leaveRoom);
        }
        window.addEventListener("popstate", leaveRoom);

        const handleUnload = () => {
            console.log("Unload");
            socket.disconnect();

            window.removeEventListener("resize", handleWindowResize);
            window.removeEventListener("popstate", leaveRoom);
        }
        window.addEventListener('beforeunload', handleUnload);

        connect();


        return () => {
            window.removeEventListener("beforeunload", handleUnload);
        }
    }, [user])

    useEffect(() => {
        
    }, [selectedMonster, isLoading, submittedIslands]);

    useEffect(() => {
        if(timer && (gamePhase.current !== 0 && gamePhase.current !== 4)){
            // console.log(seconds);
            if(seconds-1 <= 0){
                setTimer(false);
                socket.emit("timer over", ({roomName, playerLetter}));
                return;
            }
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            
            return () => clearInterval(timer);
        }
    }, [timer, seconds]);

    useEffect(() => {
        const scrollable = document.getElementById("scrollable");
        if(scrollable){
            scrollable.scrollTo(0, scrollable.scrollHeight);
        }
    }, [chat, leftModal]);

    async function getUser(){
        let stored_user = secureLocalStorage.getItem("user");
        if(stored_user && !stored_user.guest){
            setUser(stored_user);
        } else {
            let new_user = new User(0, true);
            setUser(new_user);
            secureLocalStorage.setItem("user", new_user);
        }
        setIsLoading(false);
    }

    function connect() { 

        socket.on("send room data", (room) => {
            // console.log(room);
            setTimer(false);
            setSeconds(60);
            viewingTurn.current = turnCount.current;
            parseRoom(room);
        });

        socket.on("game started", (room) => {
            gameStarted(room);
        });

        socket.on("send chat", (c) => {
            parseChat(c);
        });

        socket.on("get history", (his) => {
            setHistory(his);
        })

        socket.on("player disconnect", (room) => {
            // console.log("Opponent disconnected");
            parseRoom(room);
            setTimer(true);
        })

        // console.log(user);
        const data = {color: "red", username: user.username, title: user.title, userId: user.id}
        socket.emit("join server", (data), (response) => {
            if(!response){
                // console.log(username);
                navigate("/home", { state: {user: user}})
            } else {
                myId.current = response;
            }
        });
        

        socket.emit("join room", roomName, (response) => {
            if(!response){
                navigate("/home", { state: {user: user}})
                alert("Failed to join room");
            } else {
                playerLetter.current = response;
            }
        });
    }

    function readyUp(){
        let status = false;
        if(playerLetter.current === 'A'){
            if(Aready.current){
                status = false
            } else {
                status = true;
            }
        } else {
            if(Bready.current){
                status = false
            } else {
                status = true;
            }
        }
        socket.emit('ready', ({roomName, playerLetter, status}));
    }

    function changeTheme(newTheme){
        if(theme===newTheme) return;
        socket.emit("theme", ({roomName, theme:newTheme}))
    }

    function startGame(){
        if(!Aready.current || !Bready.current) return;
        socket.emit("start game", roomName, (response) => {
            if(!response){
                alert("Not enough players");
            }
        });
    }

    function gameStarted(room){
        gamePhase.current = room.gamePhase;
        gameStyle.current = room.gameStyle;
        botGame.current = room.botGame;
        setBoard(room.gameboard);
        setPlayers(room.users);
        myTurn.current = (room.playerTurn === playerLetter.current);

    }

    function parseRoom(room){
        console.log(room);
        if(viewingTurn.current === turnCount.current){
            viewingTurn.current++;
        }
        setBoard(room.gameboard);
        setPlayers(room.users);
        gamePhase.current = room.gamePhase;
        botGame.current = room.botGame;
        setTheme(room.gameStyle);
        Acolor.current = room.playerA.color;
        Bcolor.current = room.playerB.color;
        Aready.current = room.playerA.ready;
        Bready.current = room.playerB.ready;
        privateLobby.current = room.private;

        turnCount.current = room.turnCount;
        if(turnCount.current === viewingTurn.current){
            setHistory(room.currentHistory);
        }
        setWinner(room.winner);
        myTurn.current = (room.playerTurn === playerLetter.current);
        if(playerLetter.current === 'A'){
            setSubmittedIslands(room.playerA.islands);
        } else {
            setSubmittedIslands(room.playerB.islands);
        }

        if(isLoading){
            setIsLoading(false);
        }
    }

    function getMonsters(mine, opps){
        let newMine = [...mine]
        myMonsters.forEach((monster) => {
            newMine.forEach((my) => {
                if(my.id === monster.id){
                    my.order = monster.order;
                }
            })
        })
        setMyMonsters(newMine);


        let newOpps = [...opps]
        oppMonsters.forEach((monster) => {
            const xed = monster.xed;
            const guess = monster.guess;
            newOpps.forEach((opp) => {
                if(opp.id === monster.id){
                    opp.xed = xed;
                    opp.guess = guess;
                    opp.order = monster.order;
                }
            })
        })
        setOppMonsters(newOpps);
    }

    function sendIsland(newBoard){
        socket.emit("islands placed", ({roomName, newBoard, playerLetter}));
        setSubmittedIslands(true);
    }

    function sendMonster(newBoard, newMonster){
        myMonsters.push(newMonster);
        setSelectedMonster(null);
        setViewingMonster(null);
        setSelectedSquare(null);
        socket.emit("monster placed", ({roomName, newBoard, monsterId: newMonster}));
    }

    function monsterMoved(newBoard, lostMonsterLetter, lostMonsterId, from, to, againstBot){
        setSelectedMonster(null);
        setViewingMonster(null);
        socket.emit("monster moved", ({roomName, newBoard, lostMonsterLetter, lostMonsterId, from, to, againstBot}));
    }

    function islandAttacked(){
        socket.emit("island attacked", ({roomName, playerLetter}));
    }

    function handleSubmit(){
        switch(gamePhase.current){
            case 1:
                boardRef.current.sendYourIslands();
                break;
            case 2:
                if(selectedSquare !== null && viewingMonster !== null && (!('mine' in viewingMonster) || viewingMonster.mine)){
                    boardRef.current.sendYourMonster();
                }
                break;
            case 3:
                boardRef.current.sendYourMove();
        }
    }

    function changeMyColor(newColor){
        socket.emit("change color", ({roomName, playerLetter, newColor}));
    }

    function handleNewChat(newMessage){
        socket.emit("new chat", ({roomName, username: user.username, content: newMessage}));
    }

    function parseChat(incoming){
        let newChat = [];
        let count = 0;
        incoming.forEach((message) => {
            const data = {
                id: count,
                username: message.username,
                content: message.content,
            }
            newChat.push(data);
            count += 1;
        })
        setChat(newChat);
    }

    function returnHome(){
        // socket.emit("leave room", ({roomName}));
        navigate(`/home`, { state: {user: user}});
        socket.disconnect();
    }

    //Monster Clicking and Info

    // Called when a monster option is clicked in the helper or placer
    function monsterOptionClicked(monster) {
        setViewingMonster(monster);
    }

    // When a game piece is clicked, either on board or on side board
    function pieceClicked(piece, mine) {
        // console.log("Cliked: ", piece);
        if(gamePhase.current === 2) return;

        if(mine){
            setViewingMonster(piece);
        } else {
            if(piece.guess !== 0){
                setViewingMonster(getMonsterNames(piece.guess));
            } else {
                setViewingMonster(null);
            }
        }
        setSelectedMonster(piece);
    }

    function handleHistory(direction){
        if(direction === 0){
            viewingTurn.current = turnCount.current;
            let turn = viewingTurn.current;
            socket.emit("get history", ({roomName, turn}), (response) => {
                console.log(response);
                if(response){
                    setHistory(response);
                }
            });
        } else if(direction === -1){
            if(viewingTurn.current > 0){
                viewingTurn.current = viewingTurn.current - 1;
            }
            let turn = viewingTurn.current;
            socket.emit("get history", ({roomName, turn}), (response) => {
                console.log(response);
                if(response){
                    setHistory(response);
                }
            });
        } else {
            if(viewingTurn.current < turnCount.current){
                viewingTurn.current = viewingTurn.current + 1;
            }
            let turn = viewingTurn.current;
            socket.emit("get history", ({roomName, turn}), (response) => {
                console.log(response);
                if(response){
                    setHistory(response);
                }
            });
            
        }
    }

    function getLeftBar(){
        switch(gamePhase.current){
            case 0: 
                return <div>Game Not Started</div>;
            case 1: 
                return (
                    <div className="place_islands_container">
                        <p className="info_title">Place 6 Islands!</p>
                        <p className="info_subtitle">{submittedIslands ? "Waiting for opponent to place!" : "Hit submit to confirm your island selection!"}</p>
                        {!submittedIslands && <button onClick={handleSubmit} className="info_submit">Submit Islands</button>}
                    </div>
                );
            case 2: 
                return (
                    <div className="left_modal_inside">
                        <MonsterOptions chosen={myMonsters} setMonster={monsterOptionClicked} myTurn={myTurn.current} onSubmit={handleSubmit} theme={theme} COLORS={COLORS}/>  
                        {getMonsterInfo()}
                    </div>
                );
            case 3: 
                return (
                    <div className="left_modal_inside">
                        <MonsterHelper piece={selectedMonster} viewing={viewingMonster} setViewing={monsterOptionClicked} theme={theme} COLORS={COLORS}/>
                        {getMonsterInfo()}
                    </div>
                );
        }
    }

    //Chat Stuff
    function handleChat(event){
        event.preventDefault();
        if(newMessage === "") return
        handleNewChat(newMessage);
        setNewMessage("");
    }

    if(isLoading) {
        return (
            <Loading text={"Joining Room"}/>
        );
    }

    //Monster Info
    const getMonsterInfo = () => {

        if(gamePhase.current !== 2 && !selectedMonster){
            return (
                <div className="monster_info_container">
                    
                </div>
            );
        }

        if(!viewingMonster){
            return (
                <div className="monster_info_container">
                    
                </div>
            )
        }

        return (
            <div className="monster_info_container">
                <p className="monster_name">{viewingMonster.name}</p>
                <div className="monster_image" style={{backgroundImage: `url(${IMAGES.monsters[theme][`${viewingMonster.url}_full.png`]})`}}/>
                <p className="move_pattern_text">Move Pattern</p>
                <div className="monster_image" style={{backgroundImage: `url(${IMAGES.monsters[theme][`${viewingMonster.url}_moves.png`]})`}}/>
            </div>
        );
    };

    //Game Styles
    const getStyle = (type) => {
        switch(type){
            case "background":
                if(gamePhase.current === 0){
                    return {backgroundImage: `url(${IMAGES.backgrounds["room"]})`, backgroundSize: "cover"};;
                }
                return {backgroundImage: `url(${IMAGES.backgrounds[theme]})`, backgroundSize: "cover"};
            default:
                return null;
        }
    }

    if(gamePhase.current === 0){
        return (
            <Lobby roomName={roomName} myId={myId.current} user={user} players={players} theme={theme} Acolor={Acolor.current} Bcolor={Bcolor.current} Aready={Aready.current} Bready={Bready.current} chat={chat} readyUp={readyUp} startGame={startGame} changeTheme={changeTheme} handleNewChat={handleNewChat} changeMyColor={changeMyColor}/>
        );
    }

    if(gamePhase.current === 4){
        return (
            <div className="win_room_container" style={{backgroundImage: `url(${IMAGES.backgrounds["room"]})`, backgroundSize: "cover"}}>
                <p className="result_text">{(winner === playerLetter.current) ? "You Win!" : "You Lose!"}</p>
                <p className="thanks_text">Thanks for playing Monsters of the Sea!</p>
                <p className="thanks_text">{"This game is still in the very early stages of development and we would love any feedback.\nIf you have any gameplay suggestions or recommendations, please email us at: contact.monstersofthesea@gmail.com"}</p>
                <br></br>
                <p className="thanks_text">
                    Be sure to check back soon to see exciting new updates!
                </p>
                <button className="home_button" onClick={returnHome}>Home</button>
            </div>
        );
    }


    return (
        <div className="room_container" style={getStyle("background")} data-theme={theme}>
            <div className="left_modal" style={leftModal ? {} : {width: 0, padding: 0}}>
                {leftModal && getLeftBar()}
                <div className="left_modal_button_container" onClick={() => setLeftModal(!leftModal)} style={leftModal ? {} : {left: "1vh"}}>
                    <div className="left_modal_button_image" style={leftModal ? {backgroundImage: `url(${IMAGES.tab_button[theme]})`, transform: "rotate(-180deg)"} : {backgroundImage: `url(${IMAGES.tab_button[theme]})`}}/>
                </div>
            </div>
            <div className="center_container">
                <div className="monsters_container">
                    <div className="monsters_inside">
                        {oppMonsters.map((monster) => {
                            let color = COLORS.player_colors[Bcolor.current];
                            if(playerLetter.current === "B"){
                                color = COLORS.player_colors[Acolor.current];
                            }
                            if(selectedMonster && selectedMonster.id === monster.id && gamePhase.current !== 2){
                                // color = COLORS.piece_border_selected[theme];
                                color = COLORS.selected[theme];

                            }
                            return (
                                <div className="monster_option_square_container" key={monster.id ? monster.id : monster} onClick={() => pieceClicked(monster, false)}>
                                    <div className="monster_option_image_container" style={{borderColor: color}}>
                                        <div className="monster_option_background">
                                            <div className="monster_option_image" style={monster.guess === 0 ? {backgroundImage: `url(${IMAGES.monsters[theme][`general_side.png`]})`} : {backgroundImage: `url(${IMAGES.monsters[theme][`${monsterList[monster.guess - 1].url}_side.png`]})`}}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="room_submit_container">
                        {gamePhase.current > 2 &&
                            <div className='room_history_container'>
                                <div className="room_history_button" onClick={() => handleHistory(-1)}>
                                    <Left className="room_history_icon"/>
                                </div>
                                <div className="room_history_button" onClick={() => handleHistory(0)}>
                                    <Up className="room_history_icon"/>
                                </div>
                                <div className="room_history_button" onClick={() => handleHistory(1)}>
                                    <Right className="room_history_icon"/>
                                </div>
                            </div>
                        }
                        
                        {gamePhase.current > 1 && <p className='turn_text'>{myTurn.current ? 
                            "Your turn!"
                            :
                            "Opponent's turn"
                        }</p>}
                        <button className="submit_button" onClick={handleSubmit}>Submit</button>
                    </div>
                    
                </div>
                <Board board={board} myTurn={myTurn.current} history={history} turnCount={turnCount.current} gamePhase={gamePhase.current} playerLetter={playerLetter.current} sendIsland={sendIsland} sendMonster={sendMonster} monsterMoved={monsterMoved} islandAttacked={islandAttacked} Acolor={Acolor.current} Bcolor={Bcolor.current} selectedPiece={selectedMonster} selectedMonster={viewingMonster} setSquare={setSelectedSquare} setMonsters={getMonsters} monsterClicked={pieceClicked} theme={theme} submittedIslands={submittedIslands} ref={boardRef}/>
                <div className="monsters_container" style={{justifyContent: "flex-end"}}>
                    <div className="monsters_inside">
                        {myMonsters.map((monster) => {
                            let color = COLORS.player_colors[Acolor.current];
                            if(playerLetter.current === "B"){
                                color = COLORS.player_colors[Bcolor.current];
                            }
                            return (
                                <div className="monster_option_square_container" key={monster.id ? monster.id : monster} onClick={() => pieceClicked(monster, true)}>
                                    <div className="monster_option_image_container" style={{borderColor: color}}>
                                        <div className="monster_option_background">
                                            {monster.url && <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${monster.url}_side.png`]})`}} className="monster_option_image"/>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {chatModal ? 
                <div className="chat_modal">
                    <div className="chat_close_button" onClick={() => setChatModal(false)}>
                        <Down className="chat_close_icon"/>
                    </div>
                    <div id="scrollable" className="chat_scroll_container">
                        <div className='chat_scroll_inside'>
                            {chat.map((message) => {
                                return (
                                    <div key={message.id} className="message_container">
                                        <p className="message">{`(${message.username}): ${message.content}`}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <form className="new_chat_container" onSubmit={handleChat}>
                        <input
                            type="text"
                            value={newMessage}
                            placeholder='Message...'
                            onChange={(e) => setNewMessage(e.target.value)}
                            className="new_chat_input"
                        />
                        <Send type="submit" className="new_chat_submit" onClick={handleChat}/>
                    </form>
                </div>
                :
                <div className="chat_icon_container" onClick={() => setChatModal(true)}>
                    <Chat className="chat_open_icon"/>
                </div>
            }

            {timer && 
                <div className="timer_container">
                    <div className='timer_text'>
                        Opponent disconnected
                    </div>
                    <div className='timer_text'>
                        Waiting for reconnect...
                    </div>
                    <div className='timer_seconds'>
                        {seconds}
                    </div>
                </div>
            }
        </div>
    );
}

export default RoomPage;

export const MonsterHelper = ({piece, viewing, setViewing, theme}) => {

    const [selected, setSelected] = useState(null);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {

    }, [viewing, piece, refresh, selected]);

    function handleClick(option){
        if(piece.xed.has(option.id)){
            piece.xed.delete(option.id);
        } else {
            piece.xed.add(option.id);
        }

        if(piece.xed.size === 9){
            for(const monster of monsterList){
                if(!piece.xed.has(monster.id)){
                    piece.guess = monster.id;
                }
            }
        } else {
            piece.guess = 0;
        }
        setRefresh(!refresh);
    }

    function handleHover(option){
        setSelected(option);
        setViewing(option);
    }

    function handleUnHover(){
        setSelected(null);
    }

    function handleClear(){
        piece.xed.clear();
        piece.guess = 0;
        setRefresh(!refresh);
    }

    function getStyle(monster){
        const opacity = (selected && selected.id === monster.id) ? "0.75" : "1";
        if(piece.guess === monster.id){
            return {
                borderColor: COLORS.light[theme],
                opacity: opacity,
                filter: "brightness(1.5)"
            };
        } else if(piece.xed.has(monster.id)){
            return {
                borderColor: COLORS.light[theme],
                filter: "brightness(0.5)",
                opacity: opacity
            };
        } else {
            return {
                borderColor: COLORS.light[theme],
                opacity: opacity,
            };
        }
    }


    if(!piece) {
        return (
            <div className="monster_option_container"/>
        );
    }
    

    return (
        <div className="monster_option_container">
            <div className="monster_option_title">
                <p className="monster_option_title_text">Possible Monsters</p>
            </div>
            {('mine' in piece && piece.mine) ?
                //Displaying my Monster
                <div className="monster_option_body">
                    {monsterList.map((monster) => {
                        return (
                            <div className="monster_option_square_container" key={monster.id}>
                                <div className="monster_option_image_container" style={piece.monster_id == monster.id ? {filter: "brightness(1)"} : {filter: "brightness(0.5)"}}>
                                    <div className="monster_option_background">
                                        <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${monster.url}_side.png`]})`}} className="monster_option_image"/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                :
                //Displaying Opponents Monster
                <div className="monster_option_body">
                    {monsterList.map((monster) => {
                        // console.log(piece.xed);
                        return (
                            <div className="monster_option_square_container" key={monster.id} onClick={() => handleClick(monster)} onMouseEnter={() => handleHover(monster)} onMouseLeave={handleUnHover}>
                                <div className="monster_option_image_container" style={getStyle(monster)}>
                                    <div className="monster_option_background">
                                        <div style={{backgroundImage: `url(${IMAGES.monsters[theme][`${monster.url}_side.png`]})`}} className="monster_option_image"/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
            {!('mine' in piece && piece.mine) && <div className="monster_option_buttons">
                {/* <button onClick={handleX} className="monster_option_submit">X</button> */}
                <button onClick={handleClear} className="monster_option_submit">Clear</button>
                {/* <button onClick={handleGuess} className="monster_option_submit">Guess</button> */}
            </div>}
            
        </div>
    )
}