import axios from 'axios';
import {url} from '../utils/constants.js';

const userBaseURL = `${url}/api/users`;

class UserService {

  get(id) {
    return axios.get(userBaseURL + '/' + id);
  }

  login(info) {
    return axios.post(`${url}/auth/login`, info);
  }

  create(data) {
    return axios.post(userBaseURL, data);
  }

  update(id, user) {
    return axios.put(userBaseURL + '/' + id, {user});
  }

  delete(id) {
    return axios.delete(userBaseURL + '/' + id);
  }

}

export default new UserService();